export const AttentionReq_ExtraDocsRequested = {	 
	"en": "\n\t\t\t<p style=\"text-align: left;\">Occasionally, we are required to collect additional documentation to ensure uninterrupted gaming experience for you.</p>\n\t\t\t<br>\n\t\t\t<p><b>Additional documentation is requested from Compliance team.</b></p>\n\t\t\t<br>\n\t\t\t<p style=\"text-align: left;\"><b>Please check your e-mail for further instructions.</b> Your response is needed until {status-valid-date}. In case you need help or more information please contact our support team at {email-a-elem} or live chat.</p>\n\t\t",
	"el": "\n\t\t\t<p style=\"text-align: left;\">Περιστασιακά, μας ζητείται να συλλέξουμε πρόσθετα έγγραφα  τεκμηρίωσης  για να διασφαλίσουμε την αδιάλειπτη εμπειρία παιχνιδιού για εσάς</p>\n\t\t\t<br>\n\t\t\t<p><b>Απαιτούνται πρόσθετα έγγραφα τεκμηρίωσης από την ομάδα συμμόρφωσης.</b></p>\n\t\t\t<br>\n\t\t\t<p style=\"text-align: left;\"><b>Παρακαλώ ελέγξτε το ηλεκτρονικό ταχυδρομείο σας για περαιτέρω οδηγίες.</b> Αναμένεται η απάντησή σας μέχρι τις {status-valid-date}. Σε περίπτωση που χρειάζεστε βοήθεια ή περισσότερες πληροφορίες, επικοινωνήστε με την ομάδα υποστήριξής μας στο {email-a-elem} ή μέσω ζωντανής συνομιλίας.</p>\n\t\t",
	"es": "\n\t\t\t<p style=\"text-align: left;\">Ocasionalmente, estamos obligados a recopilar documentación adicional para garantizarle una experiencia de juego ininterrumpida. </p>\n\t\t\t<br>\n\t\t\t<p><b>Se solicita documentación adicional al equipo de Cumplimiento normativo.</b></p>\n\t\t\t<br>\n\t\t\t<p style=\"text-align: left;\"><b>Por favor revise su correo electrónico para más instrucciones.</b> Su respuesta es necesaria antes del {status-valid-date}. En caso de que necesite ayuda o más información, comuníquese con nuestro equipo de soporte en {email-a-elem} o chat en vivo. </p>\n\t\t"
}

export const ValidationRequiredText = { 
	"en": "\n\t\t\t<p>WARNING! Your account requires action for one of the following reasons:</p>\n\t\t\t<br>\n\t\t\t<ul>\n\t\t\t\t<li>You have exceeded the non-validated deposit limit of 2.000€ (or equivalent value in your currency).</li>\n\t\t\t\t<li>One or more documents you previously submitted have expired.</li>\n\t\t\t\t<li>Our Compliance Team requested validation.</li>\n\t\t\t</ul>\n\t\t\t<br>\n\t\t\t<p>Your response is needed until {status-valid-date}. In case you need help or more information please contact our support team at {email-a-elem} or live chat.</p>\n\t\t",
	"el": "\n\t\t\t<p>Προσοχή! Ο λογαριασμός σου απαιτεί ενέργεια για έναν από τους παρακάτω λόγους:</p>\n\t\t\t<br>\n\t\t\t<ul>\n\t\t\t\t<li>Έχεις υπερβεί το όριο κατάθεσης των 2.000€ (ή αντίστοιχης ισοτιμίας) μη ταυτοποιημένου λογαριασμού. </li>\n\t\t\t\t<li>Ένα ή περισσότερα έγγραφα που έχεις προηγουμένως υποβάλλει έχουν λήξει.</li>\n\t\t\t\t<li>Ζητήθηκε ταυτοποίηση από το Τμήμα Κανονιστικής Συμμόρφωσης.</li>\n\t\t\t</ul>\n\t\t\t<br>\n\t\t\t<p>Αναμένεται η απάντησή σας μέχρι τις {status-valid-date}. Σε περίπτωση που χρειάζεστε βοήθεια ή περισσότερες πληροφορίες, επικοινωνήστε με την ομάδα υποστήριξής μας στο {email-a-elem} ή μέσω ζωντανής συνομιλίας.</p>\n\t\t",
	"es": "\n\t\t\t<p>¡ADVERTENCIA! Su cuenta requiere acción por una de las siguientes razones:</p>\n\t\t\t<br>\n\t\t\t<ul>\n\t\t\t\t<li>Ha superado el límite de depósito no validado de 2.000 € (o valor equivalente en su moneda).</li>\n\t\t\t\t<li>Uno o más documentos que envió anteriormente han caducado.</li>\n\t\t\t\t<li>Nuestro Equipo de Cumplimiento solicitó la validación.</li>\n\t\t\t</ul>\n\t\t\t<br>\n\t\t\t<p>Su respuesta es necesaria antes del {status-valid-date}. En caso de que necesite ayuda o más información, comuníquese con nuestro equipo de soporte en {email-a-elem} o chat en vivo</p>\n\t\t"
}

export const UseMyCellphoneLabel = {
	"en": "Use my cellphone",
	"es": "Usar mi número de celular."
}

export const CLABE_ErrorLength = { 
	"en": "CLABE is not valid (18 digits).",
	"es": "La CLABE no es válida (18 dígitos)."
}

export const CLABE_ErrorGeneric = { 
	"en": "CLABE is not valid.",
	"es": "La CLABE no es válida."
}

export const CLABE_InvalidStartsWith = {
	'es': 'Clabe no debería empezar con {0}.',
};

export const ClabeLabel = { 
	"es": "Clabe"
}

export const GameNotAavailableInCountry = { 
	"en": "Game is not available in your country. "
}

export const acc_status_ACTIVE = {	 
	"en": "ACTIVATED",
	"es": "ACTIVADO"
}

export const acc_status_REGISTERED = { 
	"en": "REGISTERED",
	"es": "REGISTRADO"
}

export const acc_status_DISABLED = { 
	"en": "DISABLED"
}

export const acc_status_LOCKED = { 
	"en": "LOCKED"
}

export const acc_status_VALIDATED = { 
	"en": "VERIFIED",
	"es": "VERIFICADO"
}

export const acc_status_PASSIVE = { 
	"en": "PASSIVE"
}

export const acc_status_PASSIVE_VALIDATED = { 
	"en": "PASSIVE VALIDATED"
}

export const acc_status_VALIDATION_REQUIRED = { 
	"en": "VALIDATION REQUIRED"
}

export const WantBonusesViaLabel = { 
	"en": "I want to receive bonuses, promotions and communication via",
	"es": "Quiero recibir bonificaciones, promociones y comunicaciones a través de"
}

export const FundValidExtraDocs_Upper = { 
	"en": "The following additional documents may be required.",
	"es": "Es posible que se requieran los siguientes documentos adicionales."
}

export const FundValidExtraDocs_Lower = { 
	"en": "\t\t\t\t- Copy of your bank statement (needed for the wire transfer payout method)<br>\t\t\t\t- Selfie picture holding the front side of your ID or passport and a hand-written paper reading today’s date.\t\t",
	"es": "\t\t\t\t- Copia de su extracto bancario (necesario para el método de pago por transferencia bancaria)<br>\t\t\t\t- Foto de selfie sosteniendo el anverso de Tu identificación o pasaporte y un papel escrito a mano con la fecha de hoy.\t\t"
}

export const FundProofID_Upper = { 
	"en": "Proof of ID",
	"es": "Prueba de Identificación"
}

export const FundProofID_Lower = { 
	"en": "(ID or Passport)",
	"es": "(identificación o pasaporte)"
}

export const FundProofAddress_Upper = { 
	"en": "Proof of Address",
	"es": "Comprobante de Domicilio"
}

export const FundProofAddress_Lower = { 
	"en": "(Utility Bill e.g. electricity, water, gas, fixed phone, Bank Account or Reference Letter)",
	"es": "(factura de servicios públicos, por ejemplo, electricidad, agua, gas, teléfono fijo, cuenta bancaria o carta de referencia)"
}

export const FundValidationDescr = { 
	"en": `
		Do you want to withdraw your winnings? You just need to verify your account.<br><br>
		Have these documents ready:<br><br>
		<b>IDENTIFICATION</b><br><br>
		You need to upload a photo of the front and back of any of the following documents:<br><br>
		<ul>
			<li>INE (National Electoral Institute)</li>
			<li>Voter ID</li>
			<li>Driver's License</li>
			<li>Passport</li>
		</ul>
		<br><br>
		<b>SELFIE PHOTO</b><br>
		Take a selfie holding your identification. Remember, it must be a photo without filters, sunglasses, caps, or hats.

		<br><br>
		<b>PROOF OF ADDRESS</b><br>
		If the address on your identification document does not match the registered address, you will be asked for a proof of address (electricity, water, gas, landline phone bill, bank statement, reference letter, or official government letter in your name). This is to verify that the registered address matches the one on the proof of address document.

		<br><br>
		<b>Additional documents may be requested:</b><br>
		<ul>
			<li>Copy of your bank statement (required for bank transfer payment method).</li>
		</ul>

		<br><br>
		Remember, our support team is available 24/7 via Live Chat or email at {support-email}.
	`,
	"es": `
		¿Quieres retirar tus ganancias? Solo tienes que verificar tu cuenta.<br><br>
		Ten a la mano estos documentos:<br><br>
		<b>IDENTIFICACIÓN</b><br><br>
		Es necesario cargar una foto del anverso y del reverso de cualquiera de los siguientes documentos:<br><br>
		<ul>
			<li>INE (National Electoral Institute)</li>
			<li>Credencial de elector</li>
			<li>Licencia de conducir</li>
			<li>Pasaporte</li>
		</ul>
		<br><br>
		<b>FOTO SELFIE</b><br>
		Foto en modo selfie sosteniendo tu identificación. Recuerda que debe ser una foto sin filtros, sin lentes de sol, ni gorras o sombreros.

		<br><br>
		<b>COMPROBANTE DE DOMICILIO</b><br>
		En caso de que la dirección de tu documento de identidad no coincida con la de registro se te pedirá un recibo a tu nombre (electricidad, agua, gas, teléfono fijo, cuenta bancaria, carta de referencia o carta oficial del gobierno a tu nombre). Para verificar que la dirección de registro es la misma que la del recibo a tu nombre.

		<br><br>
		<b>Es posible que te pidamos documentos adicionales:</b><br>
		<ul>
			<li>Copia de tu extracto bancario (es necesario para el método de pago por transferencia bancaria).</li>
		</ul>

		<br><br>
		Recuerda que tienes a tu disposición a nuestro equipo de soporte 24/7 a través de Live Chat o por correo electrónico a {support-email}.
	`,
}

export const FundValidationVerifiedDescr = { 
	"en": "Your account is verified. You are officially a Fundalori.",
	"es": "Tuy cuenta se ha verificado. Eres oficialmente un Fundalori."
}

// export const StartVerification = {
// 	"en": "Start Verification",
// 	"es": "Comenzar Verificación"
// }

export const VerificationLabel = { 
	"en": "Verification",
	"es": "Verificación"
}

export const CurrencyUnsupported = { 
	"en": "{currency-name} currency isn't supported.",
	"el": "Το νόμισμα {currency-name} δεν υποστηρίζεται.",
	"de": "{currency-name}-Währung wird nicht unterstützt.",
	"es": "La moneda {currency-name} no es compatible."
}

export const OpenAccValidForm = { 
	"en": "Open Account Validation Form",
	"el": "Άνοιξε τη φόρμα ταυτοποίησης λογ/σμού",
	"de": "Konto-Validierungsformular öffnen",
	"es": "Formulario de validación de cuenta abierta"
}

// export const LimitSmallerVal = {
// 	"en": "The value amount should be less than that of the previous limit by the same type (which is {0}).",
// 	"el": "Η τιμή πρέπει να είναι μικρότερη από αυτή του προηγούμενου ορίου του ίδιου τύπου. ",
// 	"de": "Der Betrag sollte niedriger sein als jener des vorherigen Limits des gleichen Typs (also {0})",
// 	"es": "La cantidad del valor debe ser menor que la del límite anterior por el mismo tipo (que es {0})."
// }

export const SelLimitExists = { 
	"en": "You can create only one limit per type.",
	"el": "Μπορείς να δημιουργήσεις μόνο ένα όριο ανά τύπο ορίου.",
	"de": "Du kannst nur ein Limit pro Typ festlegen.",
	"es": "Puedes crear solo un límite por tipo."
}

// export const CountryCode = {
// 	"en": "Country Code",
// 	"el": "Κωδικός Χώρας",
// 	"de": "Ländercode",
// 	"es": "Código de país"
// }

export const UserDisabled = { 
	"en": "You cannot proceed since your account is disabled.",
	"el": "Δεν μπορείς να συνεχίσεις εφόσον ο λογ/σμός σου είναι απενεργοποιημένος.",
	"de": "Du kannst nicht fortfahren, da dein Konto deaktiviert ist",
	"es": "No puedes continuar porque tu cuenta está deshabilitada."
}

export const GameplayDisabled = { 
	"en": "Game is disabled for your account.",
	"el": "Το παιχνίδι είναι απενεργοποιημένο για το λογαριασμό σου.",
	"de": "Dieses Spiel ist für dein Konto deaktiviert.",
	"es": "El juego está deshabilitado para tu cuenta."
}

export const GameplayUserDisabled = { 
	"en": "You cannot play for money while your account is disabled.",
	"el": "Δεν μπορείς να παίξεις για χρήματα όταν ο λογ/σμός σου είναι απενεργοποιημένος.",
	"de": "Solange dein Konto deaktiviert ist, kannst du nicht um Geld spielen.",
	"es": "No puedes jugar por dinero mientras tu cuenta esté deshabilitada."
}

// export const GameplayDisabledValidReq = {
// 	"en": "You cannot play for money while your account requires validation.",
// 	"el": "Δεν μπορείς να παίξεις για χρήματα όταν ο λογ/σμός σου απαιτεί ταυτοποίηση.",
// 	"de": "Du kannst nicht um Geld spielen, solange dein Konto nicht validiert ist.",
// 	"es": "No puedes jugar por dinero mientras tu cuenta requiera validación."
// }

// export const GameplaySelfExclusion = {
// 	"en": "You cannot play for money while self-exclusion limit is active.",
// 	"el": "Δεν μπορείς να παίξεις με πραγματικά χρήματα όταν ο λογαριασμός σου είναι αποκλεισμένος.",
// 	"de": "Du kannst nicht um Geld spielen, wenn das Selbstausschlusslimit aktiviert ist.",
// 	"es": "No puedes jugar por dinero mientras el límite de autoexclusión esté activo."
// }

export const JumioAccValidInProcess = { 
	"en": "Account verification in process...",
	"el": "Ταυτοποίηση λογαριασμού σε εξέλιξη…",
	"de": "Konto-Verifizierung im Prozess…",
	"es": "Verificación de cuenta en proceso ..."
}

export const JumioAccAlreadyValid = { 
	"en": "Your account is already validated!",
	"el": "Ο λογαριασμός σου είναι ήδη ταυτοποιημένος!",
	"de": "Dein Konto ist bereits freigeschalten!",
	"es": "¡Tu cuenta ya está validada!"
}

export const validation_failure_title = { 
	"en": "Validation Failure",
	"el": "Αποτυχία Ταυτοποίησης",
	"de": "Verifizierungsfehler",
	"es": "Fallo de validación"
}

export const validation_success_title = { 
	"en": "DOCUMENTS RECEIVED!",
	"el": "Έγγραφα που ελήφθησαν",
	"de": "Erhaltene Dokumente",
	"es": "¡DOCUMENTOS RECIBIDOS!"
}

export const validation_failure = { 
	"en": "Ooops, something went wrong! Please try the validation process again or contact support for help.",
	"el": "Ωπ, κάτι πήγε στραβά! Δοκίμασε να επαναλάβεις τη διαδικασία ταυτοποίησης ή επικοινώνησε με την Εξυπηρέτηση Πελάτων.",
	"de": "Hoppla, da ist etwas schief gelaufen! Bitte versuche den Validierungsprozess erneut oder wende dich an den Kundenservice.",
	"es": "¡Vaya, algo salió mal! Vuelve a intentar el proceso de validación o ponte en contacto con el soporte técnico para obtener ayuda."
}

export const validation_success = { 
	"en": "Our verification team is already processing your documents. You will soon receive an email confirming the VERIFICATION OF YOUR ACCOUNT. If necessary, they will ask you for additional documents to verify it as soon as possible.<br><br>This process shouldn't take more than 24 hours.",
	"el": "Τα έγγραφά σου έχουν παραληφθεί. Θα ειδοποιηθείς για την πορεία της διαδικασίας ταυτοποίησης μέσω email.",
	"de": "Deine Dokumente sind eingetroffen. Du wirst per E-Mail über das Ergebnis des Kontovalidierungsprozesses informiert.",
	"es": "Nuestro equipo de verificación ya está procesando tus documentos. En breve recibirás un correo electrónico confirmando la VERIFICACIÓN DE TU CUENTA. Si es necesario, te pedirán documentos adicionales para verificarla lo antes posible. <br><br>Este proceso no debería tardar más de 24h."
}

export const AccValDocVer = { 
	"en": "Start Document Verification",
	"de": "Prüfung der Dokumente starten",
	"el": "Ξεκίνα τη Διαδικασία Ταυτοποίησης",
	"es": "Iniciar verificación de documentos"
}

export const AccValUtilityVer = { 
	"en": "Upload utility bill",
	"el": "Ανέβασε λογ/σμό ΔΕΚΟ",
	"de": "Rechnung hochladen",
	"es": "Carga de facturas de servicios"
}

export const Hidden_UploadDocumentsText_1 = { 
	"en": "Validation process requires identification by either scanning or taking pictures of the documents that prove the players' identity. The required documents are as follows:",
	"el": "Η διαδικασία ταυτοποίησης απαιτεί εξακρίβωση είτε με σκανάρισμα ή φωτογραφίζοντας τα έγγραφα που αποδεικνύουν την ταυτότητα του παίκτη. Τα απαιτούμενα έγγραφα είναι τα εξής:",
	"de": "Der Validierungsprozess erfordert eine Identifizierung durch Scannen oder Fotografieren der Dokumente, die die Identität des Spielers belegen. Die erforderlichen Dokumente sind:",
	"es": "El proceso de validación requiere identificación escaneanda o tomando fotografías de los documentos que prueban la identidad de los jugadores. Los documentos requeridos son los siguientes:"
}

export const Hidden_UploadDocumentsText_2 = { 
	"en": "Please send us the required documents by {email}, using your registered email address, or by live chat contacting one of our support agents.",
	"el": "Στείλε τα απαιτούμενα έγγραφα μέσω {email}, χρησιμοποιώντας το email εγγραφής, ή μέσω Live Chat επικοινωνώντας με έναν εκπρόσωπο.",
	"de": "Bitte sende uns die erforderlichen Dokumente per {E-Mail} unter Verwendung deiner registrierten E-Mail-Adresse oder per Live-Chat an einen unserer Kundendienst-Mitarbeiter.",
	"es": "Envíanos los documentos requeridos por {email}, utilizando tu dirección de correo electrónico registrada o por chat en vivo contactando con uno de nuestros agentes de soporte."
}

// export const Hidden_UploadDocumentsText_2_NoViber = {
// 	"en": "Please send us the required documents by {email}, using your registered email address, or by live chat contacting one of our support agents.",
// 	"el": "Στείλε τα απαιτούμενα έγγραφα μέσω {email}, χρησιμοποιώντας το email εγγραφής, ή μέσω Live Chat επικοινωνώντας με έναν εκπρόσωπο.",
// 	"de": "Bitte sende uns die erforderlichen Dokumente per {E-Mail} unter Verwendung deiner registrierten E-Mail-Adresse oder per Live-Chat an einen unserer Kundendienst-Mitarbeiter.",
// 	"es": "Envíanos los documentos requeridos por {email}, utilizando tu dirección de correo electrónico registrada o por chat en vivo contactando a uno de nuestros agentes de soporte."
// }

export const Hidden_Personal_ID_or_Passport = { 
	"en": "Personal ID or Passport",
	"el": "Ταυτότητα ή Διαβατήριο",
	"de": "Personalausweis oder Reisepass",
	"es": "Identificación personal o pasaporte"
}

export const Hidden_Utility_bill = { 
	"en": "Utility Bill",
	"el": "Λογαριασμός ΔΕΚΟ (Δ.Ε.Η., Ο.Τ.Ε.)",
	"de": "Stromrechnung",
	"es": "Factura de servicios"
}

export const Hidden_Bank_Statement = { 
	"en": "A copy of your bank statement (needed for the wire transfer payout method)",
	"el": "Αντίγραφο τραπεζικού λογαριασμού (σε περίπτωση χρήσης μεθόδου πληρωμής μέσω τράπεζας)",
	"de": "Eine Kopie deines Kontoauszugs (wird für die Auszahlungsmethode per Überweisung benötigt)",
	"es": "Copia de tu extracto bancario (necesario para el método de pago por transferencia bancaria)"
}

export const Hidden_Valid_Selfie = { 
	"en": "A selfie picture holding the front side of the ID or passport and a hand-written paper reading today’s date.",
	"de": "Ein Selfie-Foto mit der Vorderseite des Personalausweises oder Reisepasses und ein handgeschriebenes Papier mit dem heutigen Datum.",
	"el": "Μία φωτογραφία selfie κρατώντας το μπροστινό μέρος της ταυτότητας ή του διαβατηρίου και ένα χαρτί με χειρόγραφη ημερήσια ημερομηνία.",
	"es": "Una foto selfie con la cara frontal de tu documento de identidad o el pasaporte y un papel con la fecha de hoy escrita a mano"
}

export const AccProbableReq = { 
	"en": "If necessary, our support team may contact you to provide the following additional documents:",
	"de": "Falls erforderlich, wird sich unser Kundendienst mit dir in Verbindung setzen, um die folgenden zusätzlichen Dokumente zur Verfügung zu stellen:",
	"el": "Εάν κριθεί απαραίτητο, ένας εκπρόσωπος θα επικοινωνήσει μαζί σου για να ζητήσει τα παρακάτω πρόσθετα έγγραφα:",
	"es": "Si es necesario, nuestro equipo de soporte podría contactarte para proporcionarte los siguientes documentos adicionales:"
}

export const ConversionFailWithdrawReq = { 
	"en": "Conversion couldn't be completed, since you have a pending withdrawal request.",
	"el": "Η μετατροπή δεν ολοκληρώθηκε, εφόσον έχεις ένα αίτημα ανάληψης σε εκκρεμότητα.",
	"de": "Die Konversion konnte nicht abgeschlossen werden, da du einen Auszahlungsantrag ausstehend hast.",
	"es": "La conversión no se pudo completar, ya que tienes una solicitud de retiro pendiente."
}

export const ConfirmConversion = { 
	"en": "Convert my currency to {0}.",
	"el": "Μετάτρεψε τo νόμισμά μου σε {0}.",
	"de": "Konvertiere meine Währung in {0}.",
	"es": "Convertir mi moneda a {0}."
}

export const ConversionText = { 
	"en": "Please confirm that you want your account to be converted to {0} currency.",
	"el": "Παρακαλούμε επιβεβαίωσε ότι επιθυμείς ο λογαριασμός σου να αλλάξει νόμισμα σε {0}.",
	"de": "Bitte bestätige, dass du dein Konto in die Währung {0} konvertieren möchtest.",
	"es": "Confirma que deseas que tu cuenta se convierta a {0} moneda."
}

export const ConversionSuccess = { 
	"en": "Your account is now converted to {0} currency.",
	"el": "Ο λογαριασμός σου έχει αλλάξει σε {0} νόμισμα.",
	"de": "Dein Konto is jetzt in die Währung {0} konvertiert.",
	"es": "Tu cuenta está ahora convertida a {0} moneda."
}

export const ConversionError = { 
	"en": "A problem occurred while converting your account to {0} currency.",
	"el": "Παρουσιάστηκε σφάλμα κατά τη μετατροπή του λογαριασμού σου σε νόμισμα {0}.",
	"de": "Beim Umrechnen deines Kontos in die Währung {0} ist ein Problem aufgetreten.",
	"es": "Se produjo un problema al convertir tu cuenta a {0} moneda."
}

export const ConversionDuplicated = { 
	"en": "Your account is already in {0} currency.",
	"el": "Ο λογαριασμός σου είναι ήδη σε νόμισμα {0}.",
	"de": "Dein Konto ist bereits in der Währung {0}.",
	"es": "Tu cuenta ya está en {0} moneda."
}

// export const UploadDocsFormExtensions = {
// 	"en": "Please upload the following supported extensions: {0}",
// 	"el": "Παρακαλώ ανέβαστε τις ακόλουθες επεκτάσεις που υποστηρίζονται: {0}",
// 	"de": "Bitte lade die folgenden Erweiterungen hoch: {0}",
// 	"es": "Carga las siguientes extensiones compatibles: {0}"
// }

export const ValidateAccLabel = { 
	"en": "Validate your Account",
	"el": "Ταυτοποίησε τον Λογαριασμό σου",
	"de": "Verifiziere dein Konto",
	"tr": "Hesabınızı Doğrulayın",
	"es": "Valida tu cuenta"
}

// export const UploadDocsSuccessText = {
// 	"en": "Your documents have been uploaded successfully. You will be notified via e-mail regarding the validation progress.",
// 	"el": "Τα αρχεία σου έχουν αποσταλεί επιτυχώς. Θα ενημερωθείς μέσω e-mail για την πορεία της διαδικασίας ταυτοποίησης.",
// 	"de": "Deine Dokumente wurden erfolgreich hochgeladen. Du wirst per E-Mail über den Fortschritt der Verifizierung informiert.",
// 	"es": "Tus documentos se han cargado correctamente. Se te notificará por correo electrónico sobre el estado de la validación.",
// 	"se": "[se]Your documents have been uploaded successfully. You will be notified via e-mail regarding the validation progress.",
// 	"tr": "Belgeleriniz başarıyla yüklendi. Doğrulama işlemiyle ilgili olarak e-posta yoluyla bilgilendirileceksiniz."
// }

export const SWIFTLabel = { 
	"en": "SWIFT",
	"de": "SWIFT",
	"tr": "SWIFT",
	"es": "SWIFT",
	"el": "SWIFT"
}

export const IBANLabel = { 
	"en": "IBAN",
	"de": "IBAN",
	"tr": "IBAN",
	"es": "IBAN",
	"el": "IBAN"
}

// export const NoEasyPayLabel = { 
// 	"en": "No Easy Pay selected",
// 	"el": "Δεν είναι επιλεγμένο το Easy Pay",
// 	"de": "Kein Easy Pay ausgewählt",
// 	"es": "No Easy Pay seleccionado",
// 	"se": "Inget Easy Pay valt",
// 	"tr": "Easy Pay seçilmedi"
// }

export const BankLabel = { 
	"en": "Bank Country",
	"el": "Χώρα Τράπεζας",
	"de": "Land der Bank",
	"es": "País de la entidad bancaria",
	"se": "Bank",
	"tr": "Banka Ülkesi"
}

export const BankAccountLabel = { 
	"en": "Account Holder Name",
	"el": "Όνομα Κατόχου Λογ/σμού",
	"de": "Name des Kontoinhabers",
	"es": "Nombre del titular de la cuenta",
	"se": "Bankkonto",
	"tr": "Hesap Sahibinin Adı"
}

export const BankLabelFund = { 
	"en": "Bank Country",
	"es": "PAIS DEL BANCO"
}

export const BankAccountLabelFund = { 
	"en": "Account Holder Name",
	"es": "NOMBRE DEL TITULAR"
}

// export const ExcludeFromMarketingLabel = { 
// 	"en": "Exclude from marketing material",
// 	"el": "Αποκλεισμός από διαφημιστικό υλικό",
// 	"de": "Vom Marketingmaterial ausschließen",
// 	"es": "Excluir de acciones de marketing",
// 	"se": "Exkludera marknadsföringsmaterial",
// 	"tr": "Pazarlama materyali dışında tut"
// }

// export const EasyPayCCLabel = {
// 	"en": "Easy Pay CC",
// 	"el": "Easy Pay CC",
// 	"de": "Easy Pay CC",
// 	"es": "Easy Pay CC",
// 	"se": "Easy Pay CC",
// 	"tr": "Easy Pay CC"
// }

// export const EcoPayzNumberLabel = {
// 	"en": "EcoPayz Number",
// 	"de": "EcoPayz-Nummer",
// 	"tr": "EcoPayz Numarası",
// 	"es": "Numero EcoPayz",
// 	"el": "Νούμερο EcoPayz"
// }

// export const BlueberryIdLabel = {
// 	"en": "Blueberry ID",
// 	"de": "Blueberry ID",
// 	"tr": "Blueberry Kimliği",
// 	"es": "Identificación de Blueberry",
// 	"el": "Blueberry ID"
// }

export const AccStatusLabel = { 
	"en": "Account Status",
	"el": "Κατάσταση Λογαριασμού",
	"de": "Kontostatus",
	"tr": "Hesap Durumu",
	"es": "Estado de cuenta"
}

export const StateLabel = { 
	"en": "State",
	"el": "Νομός",
	"de": "Staat",
	"es": "Estado",
	"se": "Region",
	"tr": "Eyalet"
}

export const PostalCodeLabel = { 
	"en": "ZIP Code",
	"el": "Τ.Κ.",
	"de": "PLZ",
	"es": "C.P.",
	"tr": "Posta Kodu"
}

export const CountryLabel = { 
	"en": "Country",
	"el": "Χώρα",
	"de": "Land",
	"es": "País",
	"se": "Land",
	"tr": "Ülke"
}

export const UpdateLabel = { 
	"en": "Update",
	"el": "Ενημέρωση",
	"de": "Aktualisieren",
	"es": "Actualizar",
	"se": "Uppdatera",
	"tr": "Güncelle"
}

export const ChangePasswordLabel = { 
	"en": "Change password",
	"el": "Αλλαγή Κωδικού Πρόσβασης",
	"de": "Passwort ändern",
	"es": "Cambia la contraseña",
	"se": "Ändra lösenord",
	"tr": "Parolayı Değiştir"
}

export const NewPasswordLabel = { 
	"en": "New password",
	"el": "Νέος κωδικός πρόσβασης",
	"de": "Neues Passwort",
	"es": "Nueva contraseña",
	"se": "Nytt lösenord",
	"tr": "Yeni parola"
}

export const ReTypeNewPass = { 
	"en": "Re-type New Password",
	"es": "re-escribir Nueva Contraseña"
}

export const ConfirmationLabel = { 
	"en": "Confirm new password",
	"el": "Επιβεβαίωση νέου κωδικού",
	"de": "Neues Passwort bestätigen",
	"tr": "Yeni parolayı onayla",
	"es": "Confirma nueva contraseña"
}

export const SecurityIDLabel = { 
	"en": "Security ID"
}

export const SecurityNumberLabel = { 
	"en": "Security Number",
	"el": "Αριθμός Ασφαλείας",
	"de": "Sicherheitsnummer",
	"es": "Número de seguridad",
	"se": "Säkerhetsnummer",
	"tr": "Güvenlik Numarası"
}

export const UserNumberLabel = { 
	"en": "User Number",
	"el": "Αριθμός Χρήστη",
	"de": "Benutzernummer",
	"es": "Número de usuario",
	"se": "Användarnummer",
	"tr": "Kullanıcı Numarası"
}

export const SecurityLabel = { 
	"en": "Security",
	"es": "Seguridad"
}

export const ProfileLabel = { 
	"en": "Profile",
	"el": "Προφίλ",
	"de": "Profil",
	"es": "Perfil",
	"se": "Profil",
	"tr": "Profil"
}

export const FundalottoTitle = {
	en: 'Fundalotto',
};

export const ReferralTitlePage = {
	en: 'Fun&Friends'
};

export const AcceptNotifiesTab = { 
	"en": "T&C<br/>Privacy",
	"el": "Όροι και Προϋποθέσεις<br/>Πολιτική Απορρήτου",
	"de": "AGB<br>Datenschutz",
	"tr": "Şartlar ve<br/>Koşullar Gizlilik",
	"es": "Términos y condiciones<br/>de Privacidad"
}

export const AcceptNotifiesTabNoBreak = { 
	"en": "T&C Privacy",
	"es": "Términos y Privacidad"
}

export const BankAccLabel = { 
	"en": "Bank Account",
	"el": "Τραπεζικού Λογαριασμού",
	"de": "Bankkonto",
	"es": "Cuenta Bancaria"
}

export const EditBankAccLabel = { 
	"en": "Edit bank<br/>account",
	"el": "Επεξεργασία Τραπεζικού<br/>Λογαριασμού",
	"de": "Bankkonto<br>bearbeiten",
	"es": "Editar cuenta<br/>bancaria",
	"se": "Redigera<br/>bankkonto",
	"tr": "Banka hesabını düzenle"
}

export const ValidationProcedureLabel = { 
	"en": "Validate Your<br/>Account",
	"el": "Ταυτοποίησε τον<br/>λογαριασμό σου",
	"de": "Verifiziere<br>dein Konto",
	"tr": "Hesabınızı Doğrulayın",
	"es": "Valida tu<br/>cuenta"
}

export const ValidationProcedureTitle = { 
	"en": "Validate Your Account",
	"el": "Ταυτοποίησε τον λογαριασμό σου",
	"de": "Verifiziere<br>dein Konto",
	"tr": "Hesabınızı Doğrulayın",
	"es": "Valida tu cuenta"
}

export const ChangePassLabel = {
	"en": "Change<br/>Password",
	"el": "Αλλαγή Κωδικού<br/>Πρόσβασης",
	"de": "Passwort<br>ändern",
	"es": "Cambia la<br/>contraseña",
	"se": "Ändra lösenord",
	"tr": "Parolayı Değiştir"
}

export const EditLimitsLabel = {
	"FOUR_SG": {
		"en": "Responsible<br/>Gaming",
		"el": "Υπεύθυνο<br/>Παιχνίδι",
		"de": "Verantwortungsbewusstes<br>Spielen",
		"es": "Juega con<br/>responsabilidad",
		"se": "Redigera gränser",
		"tr": "Sorumlu Oyun"
	},
	"FUND": {
		"en": "Responsible<br/>Gaming",
		"es": "Juego<br/>Responsable"
	}
}

export const EditLimitsLabelNoBreak = { 
	"FOUR_SG": {
		"en": "Responsible Gaming",
		"el": "Υπεύθυνο Παιχνίδι",
		"de": "Verantwortungsbewusstes Spielen",
		"es": "Juega con responsabilidad",
		"se": "Redigera gränser",
		"tr": "Sorumlu Oyun"
	},
	"FUND": {
		"en": "Responsible Gaming",
		"es": "Juego Responsable"
	}
}

export const TermsAndCondAcceptanceText = { 
	"en": "You accepted our {0}.",
	"el": "Έχεις αποδεχτεί τους {0} μας.",
	"de": "Du hast unsere {0} akzeptiert.",
	"tr": "{0}ımızı kabul ettiniz.",
	"es": "Has aceptado nuestros {0}."
}

export const PrivacyAcceptanceText = { 
	"en": "You accepted our {0}.",
	"el": "Έχεις αποδεχτεί την {0} μας.",
	"de": "Du hast unsere {0} akzeptiert.",
	"tr": "{0} kabul ettiniz.",
	"es": "Has ha aceptado nuestra {0}."
}

export const MarketingOptionsTitle = { 
	"en": "Marketing options",
	"el": "Επιλογές Ενημέρωσης",
	"de": "Marketingoptionen",
	"tr": "Pazarlama seçenekleri",
	"es": "Opciones de marketing"
}

export const PrivacyRecieveAllOptionText = { 
	"en": "I want to receive news about bonuses, offers, free games and services via following channels:",
	"el": "Επιθυμώ να λαμβάνω νέα για μπόνους, προσφορές, δωρεάν παιχνίδια και υπηρεσίες μέσω:",
	"de": "Ich möchte Neuigkeiten über Bonusse, Angebote, kostenlose Spiele und Dienste über folgende Kanäle erhalten:",
	"tr": "Aşağıdaki kanallardan bonuslar, teklifler, ücretsiz oyunlar ve hizmetler hakkında haberler almak istiyorum:",
	"es": "Me gustaría recibir noticias sobre bonos, ofertas, juegos gratuitos y servicios a través de los siguientes canales:"
}

export const PrivacyEmailOption = { 
	"en": "Email newsletters are sent to inform you about all our bonuses and offers.",
	"el": "Email ειδοποιήσεις για τα μπόνους και τις προσφορές.",
	"de": "E-Mail Newsletter informieren dich über alle Bonusse und Angebote.",
	"tr": "E-posta bültenleri, tüm bonuslar ve teklifler hakkında sizi bilgilendirir.",
	"es": "Los boletines por correo electrónico te informan sobre todos los bonos y ofertas."
}

export const SMSLabel = { 
	"en": "SMS"
}

export const PrivacySMSOption = { 
	"en": "SMS messages are sent occasionally to announce some important updates.",
	"el": "Μηνύματα τα μηνύματα χρησιμοποιούνται περιστασιακά για να ανακοινώνουμε σημαντικά γεγονότα.",
	"de": "SMS Mitteilungen werden gelegentlich verwendet, um ein wichtiges Ereignis anzukündigen.",
	"tr": "SMS mesajları, zaman zaman bazı önemli olayları duyurmak için kullanılır.",
	"es": "Los mensajes de SMS se usan ocasionalmente para anunciar algún evento importante."
}

export const CallsLabel = { 
	"en": "Calls",
	"es": "LLAMADAS"
}

export const PrivacyCallOption = { 
	"en": "Call we may call you directly in case of a personalized offer we feel is important for you.",
	"el": "Τηλέφωνο υπάρχει περίπτωση να σε καλέσουμε εάν είναι διαθέσιμη κάποια προσωπική προσφορά που πιστεύουμε πως είναι σημαντική για εσένα.",
	"de": "Anruf Wir können dich direkt anrufen, falls wir ein wichtiges personalisiertes Angebot für dich haben.",
	"tr": "Arama, sizin için önemli olduğunu düşündüğümüz kişiselleştirilmiş bir teklif bulunması durumunda sizi doğrudan arayabiliriz.",
	"es": "Llamadas: Podríamos llamarte directamente en caso de una oferta personalizada que consideremos importante para ti."
}

export const Notifications = { 
	"en": "Notifications",
	"el": "Ειδοποιήσεις",
	"de": "Benachrichtigungen",
	"tr": "Bildirimler",
	"es": "Notificaciones"
}

export const PrivacyNotifiOptionAccept = { 
	"en": "Yes, I want to receive notifications regarding bonuses and news.",
	"el": "Ναι, επιθυμώ να λαμβάνω ειδοποιήσεις για προσφορές και νέα.",
	"de": "Ja, ich möchte Benachrichtigungen über Boni und Neuigkeiten erhalten.",
	"es": "Sí, quiero recibir notificaciones sobre bonificaciones y novedades.",
	"tr": "Evet, Casino'dan özel bonus, komisyon iadesi aldığımda, çekiliş kazandığımda ya da başka önemli ödüller aldığımda bildirim almak istiyorum."
}

export const PrivacyNotifiOptionDecline = { 
	"en": "No, I am not interested in bonuses and news.",
	"el": "Όχι, δεν με ενδιαφέρουν οι προσφορές και τα νέα.",
	"de": "Nein, ich interessiere mich nicht für Boni und Neuigkeiten.",
	"es": "No, no me interesan las bonificaciones y las novedades.",
	"tr": "Hayır, teşekkürler, casino hesabıma giriş yaparak elle kontrol edeceğim."
}