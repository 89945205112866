import model from "@js/model.js";
import { setLocationSearch, clearLocationSearch, isUndefined, toArray, exec } from "@helpers/utils.js"
import Enum from "@shared/enum.js";

var LATEST_GAME_STORAGE_KEY = 'latest-game-from-';

var PAGE_SPECIFIC_CATS_MAP = ['PROMO_INFO', 'X1', 'PAGE_NOT_FOUND', Enum.Pages.WEBRES];
var PAGE_SPECIFIC_CATS_AUTH_MAP = ['X1'];

import menuLang from "@lang/menu.js";
import { NewLabel, TopLists, WelcomeBonus, WelcomeBonusBr } from "@lang/user.js";

export default {
	initCategories(){
		model.pushLang(menuLang);
		model.pushLang({WelcomeBonus, WelcomeBonusBr, TopLists, NewLabel});

		model.observables.activePage = null;

		var allCategories = model.navigation;
		this.parseAllCategories(allCategories);
		delete this.catIdPool;

		PAGE_SPECIFIC_CATS_MAP.forEach(label => {
			Enum.CATEGORY_IDS[label] = label;
			Enum.Pages[label] = label;
			
			var props = {
				id: label,
				page: label,
			};

			if(PAGE_SPECIFIC_CATS_AUTH_MAP.includes(label)) props.auth = true;

			allCategories.push(props);
		});

		model.categories = {
			level1: allCategories,
		};

		for(var i = 1; i <= Enum.MAX_CATEGORY_RECURSION; i++) setupCatLevel.call(this, i);

		inner.call(this);

		function inner(){
			if(model.multipage) {
				if(model.isMobile && sessionStorage.getItem('PrevCategory')) this.removeStartingCategory();
				this.setupMultiPageAfterPageLoad();
			}
			else{
				if(model.isView(Enum.Views.MAIN) && this.getPaths().length > this.getMultiPagePathsToSkip()){
					if(!this.handleExternalPages()){
						this.redirTo404Page();
						return;
					}
				}

				if(!model.currCatIds){
					model.currCatIds = (() => {
						var ids = {level1: model.categories.level1[0].id};
						var prevCat = sessionStorage.getItem('PrevCategory');
						if(prevCat){
							prevCat = JSON.parse(prevCat);
							ids = prevCat;
							this.removeStartingCategory();
						}
						if(model.startingLvl1Cat){
							if(!model.isUI('FUND') && [Enum.CATEGORY_IDS.OFFERS, Enum.CATEGORY_IDS.TOURS].includes(model.startingLvl1Cat)){
								delete model.startingLvl1Cat;
								setLocationSearch({
									promoSection: true,
								});
							}
							else{
								var _lvl1Cat = Number(model.startingLvl1Cat);
								ids = {level1: isNaN(_lvl1Cat) ? model.startingLvl1Cat : _lvl1Cat};
							}
							clearLocationSearch('openCatById');
						}
						return ids;
					})();
				}
			}

			model.on('GoToCategory', 'ForceActiveCat', e => {
				var prevLevel2Cat = this.getCatByCatID(model.currCatIds.level2);
				setupCurrLevelProps.call(this, e.ids);
				handleAnalyticsEvents.call(this, e.ids, (prevLevel2Cat && prevLevel2Cat.id !== e.ids.level2));
			});

			var prevPage;
			model.on('GoToCategory', e => {
				if(typeof e.ids.level1 === 'undefined') return;

				var targetPage = fetchTargetPage.call(this, e.ids);

				var bool = targetPage != Enum.Pages.PROMO_INFO ? prevPage !== targetPage : true;
				bool && model.dispatchEvent('OpenPage', {
					page: targetPage,
				});
				prevPage = targetPage;
			});

			model.observables.activePage = fetchTargetPage.call(this, model.currCatIds);

			model.on('LogoutComplete', () => {
				for(var i = 1; i <= Enum.MAX_CATEGORY_RECURSION; i++){
					var cat = this.getCatByCatID(model.currCatIds['level' + i], i);
					if(cat && cat.auth){
						this.gotoCategory({level1: model.categories.level1[0].id}, false, undefined, undefined, undefined, false);
						break;
					}
				}
			});

			model.on('SetLang', () => {
				var cat = this.getCatByCatID(model.currCatIds.level2, 2);
				if(!cat) return;

				if(
					(cat.disabledForLang.length > 0 && cat.disabledForLang.includes(model.langKey)) || 
					(cat.enabledForLang.length > 0 && !cat.enabledForLang.includes(model.langKey))
				){
					this.gotoCategory({level1: model.currCatIds.level1}, false, undefined, undefined, undefined, false);
				}
			});

			setTimeout(() => this.gotoCategory(model.currCatIds, false, undefined, undefined, undefined, false), 0);
		}
	},
	setGameAsLatestById(latestGameById, catID){
		var key = LATEST_GAME_STORAGE_KEY + catID;
		localStorage.setItem(key, latestGameById);
	},
	getCatByCatID(catID, level){
		if(!level){
			var cat = null;
			for(var i = 1; i <= Enum.MAX_CATEGORY_RECURSION; i++){
				cat = this.getCatByCatID(catID, i);
				if(cat) break;
			}
			return cat;
		}

		var levelStr = 'level' + (isUndefined(level) ? 1 : level);
		for(var i = 0, j = model.categories[levelStr].length; i < j; i++){
			var cat = model.categories[levelStr][i];
			if(cat['id'] == catID) return cat;
		}
	},
	getCatsByPageID(pageID){
		var arr = [];
		for(var i = 0, j = model.categories.level1.length; i < j; i++){
			var cat = model.categories.level1[i];
			if(cat.page == pageID)
				arr.push(cat);
		}
		return arr;
	},
	gotoCategory(ids, forceScroll, scroll, isPopState, promoId, userInteracted, ignoreIsSame){
		if(typeof userInteracted !== 'boolean') userInteracted = true;
		if(typeof ignoreIsSame !== 'boolean') ignoreIsSame = false;
		
		this.whenAppReady(() => inner.call(this));

		function inner(){
			if(typeof isPopState !== 'boolean') isPopState = false;
			if(typeof forceScroll !== 'boolean') forceScroll = true;
			
			var prevLevel1 = model.currCatIds['level1'];
			var prevLevel2 = model.currCatIds['level2'];
			var prevLevel3 = model.currCatIds['level3'];

			!ids.level1 && setupFirstSubCat.call(this, ids, 0);

			if(!ids.level2) setupFirstSubCat.call(this, ids, 1);
			else setupLegitParentCat.call(this, ids, 2);

			if(!ids.level3) setupFirstSubCat.call(this, ids, 2);
			else setupLegitParentCat.call(this, ids, 3); 
			
			let queryParams = ids.queryParams;

			if(model.multipage){
				if(!ignoreIsSame && prevLevel1 == ids.level1 && prevLevel2 == ids.level2 && prevLevel3 == ids.level3){
					execGoToCat.call(this);
					return;
				}

				if(ids.level1 !== Enum.CATEGORY_IDS.WEBRES) model.webResInfo = null;

				if(promoId) this.setUrlPathnameForPromo(promoId);
				else{
					if(model.webResInfo){
						if(model.webResInfo.path && this.formatMultipagePath(model.webResInfo.path, true, true, false) !== this.getMultiPagePath()){
							this.multipageRedir([model.webResInfo.path], false);
						}
					}
					else{
						let multipage = this.getCatPathsAllLevels(ids);
						// if(!model.isApp && prevLevel1 != ids.level1){ //remove this entire if block to enable single app everywhere
						// 	this.multipageRedir(multipage, true, undefined, undefined, queryParams);
						// 	return;
						// }
						this.multipageRedir(multipage, false, undefined, undefined, queryParams);
					}
				}

				execGoToCat.call(this);
				return;
			}
			
			execGoToCat.call(this);
			
			function execGoToCat(){
				model.dispatchEvent('GoToCategory', {ids, forceScroll, scroll, isPopState, promoId, userInteracted});
			}
		}
	},
	getCatPathsAllLevels(ids){
		var res = [];

		ids.level1 && res.push(getCatPathById.call(this, ids.level1));
		ids.level2 && res.push(getCatPathById.call(this, ids.level2));
		ids.level3 && res.push(getCatPathById.call(this, ids.level3));

		return res;
	},
	updateStartingCategory(){
		if(!model.isMobile) return;
		window.sessionStorage.setItem('PrevCategory', model.redeemLangObj({
			FOUR_SG: () => JSON.stringify(model.currCatIds),
			FUND: () => window.location.pathname,
		})());
	},
	removeStartingCategory(){
		window.sessionStorage.removeItem('PrevCategory');
	},
	parseAllCategories(categories, parentCat){
		for(var i = 0, j = categories.length; i < j; i++){
			var cat = categories[i];

			//additionaly checking if a category id had been
			//accidentally duplicated inside of the config file
			if(!this.catIdPool) this.catIdPool = [];
			
			if(!this.catIdPool.includes(cat.id)) this.catIdPool.push(cat.id);
			else throw "Category ID '" + cat.id + "' is duplicated. ";

			cat.disabledForLang = toArray(cat.disabledForLang);
			cat.enabledForLang = toArray(cat.enabledForLang);

			if(typeof cat.mobile !== 'boolean') cat.mobile = true;
			if(typeof cat.prod !== 'boolean') cat.prod = true;
			// if(typeof cat.ignoreDelete !== 'boolean') cat.ignoreDelete = false;
			if(typeof cat.catsAsGameLists !== 'boolean') cat.catsAsGameLists = false;
			if(typeof cat.rememberOnLastVisit !== 'boolean') cat.rememberOnLastVisit = false;

			if(	(model.isMobile && !cat.mobile) || 
				(model.isProd && !cat.prod)
			){
				categories.splice(i, 1);
				i--;
				j--;
				continue;
			}

			cat.hasGames = false;

			var targetCMeterType = parentCat ? parentCat.gameGroup : Enum.GameGroups.Lobby;
			cat.gameGroup = cat.gameGroup || targetCMeterType;
			cat.page = cat.page || Enum.Pages.LOBBY;

			if(typeof cat.auth !== 'undefined' && typeof cat.auth !== 'boolean') cat.auth = exec(cat.auth, this.model);
			if(Array.isArray(cat.categories)) this.parseAllCategories(cat.categories, cat);
		}
	}
};

function getCatPathById(catId){
	var cat = this.getCatByCatID(catId);
	return model.redeemLangObj(cat.path);
}

function fetchTargetPage(ids){
	var catLevel1 = (ids && ids.level1) ? this.getCatByCatID(ids.level1, 1) : setupFirstSubCat.call(this, ids, 1);
	var catLevel2 = (ids && ids.level2) ? this.getCatByCatID(ids.level2, 2) : setupFirstSubCat.call(this, ids, 2);
	return (catLevel2 && catLevel2.page) ? catLevel2.page : catLevel1.page;
}

function setupFirstSubCat(ids, level){
	var catID = ids['level' + level];
	if(typeof catID === 'undefined' && (level === 0 || !level)){
		var lvl1 = model.categories.level1;
		if(Array.isArray(lvl1) && lvl1.length > 0)
			ids['level1'] = lvl1[0].id;
		return;
	}

	var cat = this.getCatByCatID(catID);
	if(!cat)
		return;

	var subCats = cat.categories;
	if(!cat.catsAsGameLists && Array.isArray(subCats) && subCats.length > 0){
		var catInner = subCats[0];
		ids['level' + (level + 1)] = catInner.id;
	}
}

function setupLegitParentCat(ids, level){
	var catProp = 'level' + level;
	var catID = ids[catProp];
	var cat = this.getCatByCatID(catID);
	if(!cat || !cat.parentId) return;

	ids['level' + (level - 1)] = cat.parentId;
}

function setupCatLevel(currLevel){
	var newLevel = currLevel + 1;
	var currLevelStr = 'level' + currLevel;
	var newLevelStr = 'level' + newLevel;
	
	var currLevelCats = model.categories[currLevelStr];
	if(!Array.isArray(currLevelCats))
		return;
		
	model.categories[newLevelStr] = [];
	
	for(var i = 0, j = currLevelCats.length; i < j; i++){
		var cat = model.categories[currLevelStr][i];
		if(!cat.categories)
			continue;

		for(var ind in cat.categories){
			var subCat = cat.categories[ind];
			subCat.parentId = cat.id;
			subCat.parent = cat;
		}

		model.categories[newLevelStr] = (model.categories[newLevelStr] || []).concat(cat.categories);
	}
}

function setupCurrLevelProps(ids){
	for(var i = 1; i <= Enum.MAX_CATEGORY_RECURSION; i++){
		var targetLvl = 'level' + i;
		var prevLvl = 'level' + (i - 1);
		var prevCat = model.categories[prevLvl] && model.categories[prevLvl].find(item => item.id == model.currCatIds[prevLvl]);
		if(!isUndefined(ids[targetLvl])){
			if(prevCat){
				if(!prevCat.catsAsGameLists)
					model.currCatIds[targetLvl] = ids[targetLvl];
				else
					model.currCatIds[targetLvl] = null;
			}
			else
				model.currCatIds[targetLvl] = ids[targetLvl];
		}
		else if(i > 1){
			if(prevCat && !prevCat.catsAsGameLists && Array.isArray(prevCat.categories) && prevCat.categories.length > 0)
				model.currCatIds[targetLvl] = prevCat.categories[0].id;
			else
				model.currCatIds[targetLvl] = null;
		}
	}
	model.dispatchEvent('CurrCatIDs');
}

function handleAnalyticsEvents(ids, notifyLevel2){
	if(notifyLevel2){
		var catLevel2 = this.getCatByCatID(ids.level2);
		if(catLevel2){
			model.dispatchEvent('GameNavRedirect', {
				name: getAnalyticsName.call(this, catLevel2),
			});
		}
	}
	var catLevel3 = this.getCatByCatID(ids.level3);
	if(catLevel3){
		model.dispatchEvent('SubGameNavRedirect', {
			mainCatName: catLevel3.parent ? getAnalyticsName.call(this, catLevel3.parent) : null,
			subCatName: getAnalyticsName.call(this, catLevel3),
		});
	}
}

function getAnalyticsName(cat){
	return cat.name ? cat.name : cat.label ? model.getStr(cat.label, 'en') : null;
}